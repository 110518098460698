import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FormInput from "./FormInput";
import LoginService from "../../Services/LoginService";
import Spinner from "../Spinner/Spinner";
import { isTokenValid } from "../../Services/AuthService";
//import { checkServiceResponse } from "../../Services/AuthService";
import styles from "./LoginPage.module.css";
import { Context } from "../../Store/Store";
import { setUserDataLocalStorage } from "../../Scripts/getSetLocalStorage";
import { TransformAPIDataToUserData } from "../../Services/UserDataService";
import AccountActivationService, { SendAccountReactivationEmailService } from "../../Services/AccountActivation";

const LoginPage = (props) => {
  const location = useLocation();
  const { pathname, message } = props;
  const [loading, setIsLoading] = useState(false);
  const [responseValues, setResponseValues] = useState({ response: "" });
  const d = new Date();
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();

  const context = useContext(Context);

  let history = useNavigate();

  const redirect = useCallback(
    (path) => {
      history(path);
    },
    [history]
  );

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  const uidb64 = urlParams.get("uid");
  const pwb64 = urlParams.get("secret");
  if (token && uidb64 && pwb64) {
    AccountActivationService(token, uidb64, pwb64).then((res) => {
      res.json().then((json) => {
        if (res.status === 200) {
          const email = json.data.email;
          const pw = json.data.pw;
          loginHandler("dummy", {
            email: email,
            password: pw,
          });
        }
      });
    });
  }

  useEffect(() => {
    if (window.self !== window.top) {
      // The page is embedded in an iframe
      // Navigate to top window
      window.top.location = window.self.location;
    }
    const authed = isTokenValid();
    const t = localStorage.getItem("user");
    const u = JSON.parse(t);
    const token = localStorage.getItem("token");
    if (u) {
      //const group = u.group;
      document.cookie = "fp_email=" + u.email + ";" + expires + "; path=/";
      document.cookie = "fp_user_type=" + u.group + ";" + expires + "; path=/";
      document.cookie = "fp_user_trial_end_date=" + u.trial_end_date + ";" + expires + "; path=/";
      document.cookie = "eai_token=" + token + ";" + expires + "; path=/";
      if (authed && authed.status) {
        history("/Home");
      }
    }

    return () => {};
  }, [history, message, pathname, expires, token, uidb64]);

  const setUserData = (json) => {
    const data = TransformAPIDataToUserData(json);
    setUserDataLocalStorage(data, json.data.token);

    // useContext dispatch
    context[1]({
      type: "SET_USER",
      payload: {
        data,
      },
    });
  };

  const loginHandler = async (e, input) => {
    setIsLoading(true);
    LoginService(input.email, input.password)
      .then((res) => {
        res.json().then((json) => {
          //let checkServiceRes = checkServiceResponse(json, res);
          setIsLoading(false);
          if (
            res.status === 200 &&
            (json.message === "external" ||
              json.message === "payment stopped" ||
              json.data.user.group === "Growth Plan")
          ) {
            // Handle successful Login for non-paying members
            document.cookie = "fp_email=" + json.data.user.email + ";" + expires + "; path=/";
            document.cookie = "fp_user_type=" + json.data.user.group + ";" + expires + "; path=/";
            document.cookie = "fp_user_trial_end_date=" + json.data.user.trial_end_date + ";" + expires + "; path=/";
            document.cookie = "eai_token=" + json.data.token + ";" + expires + "; path=/";
            setUserData(json);
            if (location.state) {
              redirect(location.state.from.pathname);
            } else {
              redirect("/Home");
            }
          } else if (res.status === 200) {
            // Handle successful login for members
            document.cookie = "fp_email=" + json.data.user.email + ";" + expires + "; path=/";
            document.cookie = "fp_user_type=" + json.data.user.group + ";" + expires + "; path=/";
            document.cookie = "fp_user_trial_end_date=" + json.data.user.trial_end_date + ";" + expires + "; path=/";
            document.cookie = "eai_token=" + json.data.token + ";" + expires + "; path=/";
            setUserData(json);
            if (location.state) {
              redirect(location.state.from.pathname);
            } else {
              redirect("/Dashboard");
            }
          } else if (res.status === 403 && json.message === "Account no longer active") {
            // Handle inactive user
            setResponseValues({
              response:
                "Your account is not active. Please click on the account activation link we just sent to your email.",
            });
            SendAccountReactivationEmailService(input.email, input.password);
          } else if (res.status === 403) {
            // Handle non-existent user
            setResponseValues({ response: "Invalid credentials." });
          } else if (res.status === 429) {
            // too many requests
            setResponseValues({ response: "Too many requests. Please wait 1 minute." });
          } else {
            setResponseValues({ response: "Something went wrong. Please contact support." });
          }
        });
      })
      .catch((error) => {
        setResponseValues({ response: "Unable to contact server. " + error });
        setIsLoading(false);
        return error;
      });
  };

  const [pauseImage, setPauseImage] = useState(false);
  const handleMouseEnter = () => {
    setPauseImage(true);
  };
  const handleMouseLeave = () => {
    setPauseImage(false);
    setCurrentIndex(currentIndex + 1);
  };

  const changeImage = (e) => {
    let image = e.currentTarget.id;
    if (image === "lead_monitoring") {
      setCurrentIndex(1);
    } else {
      setCurrentIndex(0);
    }
  };

  const hashtag_trends = (
    <div
      style={{ background: "#2F3EAC", height: "100vh", flexBasis: "50%" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ padding: "20px 50px 0px" }}>
        <div style={{ textAlign: "center", marginBottom: "15px", height: "117px" }}>
          <img src="/images/1_hashtag.svg" alt="FILT Pod"></img>
        </div>
        <div style={{ color: "white", textAlign: "center", height: "500px" }}>
          <div style={{ height: "90px", marginBottom: "40px" }}>
            <div style={{ lineHeight: "28px", fontSize: "16px", fontWeight: "400" }}>
              <div>
                Seize LinkedIn hashtags with rapid follower growth for more content exposure by monitoring their
                behavioral trends.
              </div>
            </div>
          </div>
          <div style={{ background: "white", borderRadius: "20px", padding: "10px" }}>
            <img src="/images/hashtag_trends_description_small.jpg" alt="Hashtag Trends"></img>
          </div>
        </div>
        <div>
          <div style={{ width: "max-content", margin: "auto" }} className="row">
            <div id="hashtag_trends" style={{ margin: "20px 5px", cursor: "pointer" }} onClick={changeImage}>
              <img src="/images/ellipse_selected.svg" alt=""></img>
            </div>
            <div id="lead_monitoring" style={{ margin: "20px 5px", cursor: "pointer" }} onClick={changeImage}>
              <img src="/images/ellipse.svg" alt=""></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const lead_monitoring = (
    <div
      style={{ background: "#2F3EAC", height: "100vh", flexBasis: "50%" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ padding: "20px 50px 0px" }}>
        <div style={{ textAlign: "center", marginBottom: "15px", height: "117px" }}>
          <img src="/images/2_engage.svg" alt="FILT Pod"></img>
        </div>
        <div style={{ color: "white", textAlign: "center", height: "500px" }}>
          <div style={{ height: "90px", marginBottom: "40px" }}>
            <div style={{ lineHeight: "28px", fontSize: "16px", fontWeight: "400" }}>
              Use AI to write insightful comments, saving hours of your time by engaging and increasing touchpoints with
              multiple LinkedIn leads for faster conversions.
            </div>
          </div>
          <div style={{ background: "white", borderRadius: "20px", padding: "10px" }}>
            <img src="/images/lead_monitoring.jpg" alt="Lead Monitoring"></img>
          </div>
        </div>
        <div>
          <div style={{ width: "max-content", margin: "auto" }} className="row">
            <div id="hashtag_trends" style={{ margin: "20px 5px", cursor: "pointer" }} onClick={changeImage}>
              <img src="/images/ellipse.svg" alt=""></img>
            </div>
            <div id="lead_monitoring" style={{ margin: "20px 5px", cursor: "pointer" }} onClick={changeImage}>
              <img src="/images/ellipse_selected.svg" alt=""></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (pauseImage) {
        return;
      }
      if (currentIndex >= 2) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [currentIndex, pauseImage]);

  return (
    <div className={`${styles.main} flex-nowrap `}>
      <div className={`${styles.row}`}>
        {currentIndex === 1 ? lead_monitoring : hashtag_trends}
        <div className={`${styles.rightbackground} col-6 `} style={{ flexBasis: "50%" }}>
          {loading ? <Spinner /> : ""}
          <FormInput loginHandler={loginHandler} responseValues={responseValues} />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
