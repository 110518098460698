import { useContext } from "react";
import { Context } from "../../../Store/Store";
import { NavLink } from "react-router-dom";

import Styles from "./Nav.module.css";
import { Button, IconButton, styled } from "@mui/material";

import { IoOptions, IoExtensionPuzzle } from "react-icons/io5";
import { FaHashtag, FaPhoneAlt, FaFacebookF } from "react-icons/fa";
import { HiOutlineViewGrid } from "react-icons/hi";
import { MdGroups } from "react-icons/md";
import { LuHeadphones } from "react-icons/lu";
import { BiSolidMessageDetail } from "react-icons/bi";
import { FaLinkedinIn, FaCheck } from "react-icons/fa6";
import { MdOutlineRocketLaunch, MdInsights } from "react-icons/md";
import { RxPencil1 } from "react-icons/rx";

const styles = {
  Nav: {
    display: "flex",
    flexDirection: "column",
    height: " 100%",
    gap: "5px",
    padding: 0,
    margin: 0,
  },
  SocialMediaBox: {
    display: "flex",
    alignItems: "center",
    gap: ".8rem",
    marginTop: "5px",
    padding: "0 0 0 12px",
  },
  SocialMedia: {
    background: "white",
    height: "26px",
    width: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  SocialMediaIcon: {
    height: "18px",
    width: "18px",
    color: "#2f3eac",
  },

  UpgradeBtn: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    backgroundColor: "white",
    color: "#2f3eac",
    borderRadius: "10px",
    fontWeight: "600",
    fontFamily: "poppins",
    lineHeight: "20px",
    textTransform: "none",
    margin: "0 0 0 12px",
    padding: "8px 24px",
    textDecoration: "none",
    "&:hover": {
      background: "white",
      color: "#2f3eac",
      textDecoration: "none",
    },
  },
  ProIcon: {
    height: "18px",
    width: "18px",
  },
};

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  "&:link, &:visited": {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    gap: "12px",
    color: "white",
    fontSize: "16px",
    fontWeight: "600",
    padding: "6px 12px",
    transition: "all 0.3s",
    textDecoration: "none",
  },

  "&:hover, &:active, &.active:link, &.active:visited": {
    color: "#2f3eac",
    backgroundColor: "white",
    borderRadius: "10px",
    textDecoration: "none",
  },
  "& svg": {
    width: "24px",
    height: "24px",
    color: "white",
    transition: "all 0.3s",
  },
  "&:hover svg, &:active svg, &.active:link svg, &.active:visited svg": {
    color: "#2f3eac",
  },
}));

function Nav() {
  const context = useContext(Context);
  const state = context[0];
  const premiumUser =
    state.user?.data?.group === "Premium" ||
    state.user?.data?.group === "Growth Plan" ||
    state.user?.data?.group === "Member" ||
    state.user?.data?.group === "Starter" ||
    state.user?.data?.group === "Trial";
  return (
    <ul style={styles.Nav}>
      <li>
        <StyledNavLink to="/Home">
          <HiOutlineViewGrid />
          <span>Dashboard</span>
        </StyledNavLink>
      </li>
      <li>
        <StyledNavLink to="/NurtureProspects">
          <MdGroups />
          <span>Monitor</span>
        </StyledNavLink>
      </li>
      <li>
        <StyledNavLink to="/CustomizeAI">
          <IoOptions style={{ transform: "rotateY(180deg)", strokeWidth: "20px" }} />
          <span>Customize AI</span>
        </StyledNavLink>
      </li>
      <li>
        <StyledNavLink to="/LinkedinHashtagTrend">
          <FaHashtag />
          <span>Hashtag</span>
        </StyledNavLink>
      </li>
      <li>
        <StyledNavLink to="/Integrations">
          <IoExtensionPuzzle />
          <span>Integrations</span>
        </StyledNavLink>
      </li>
      {(state.user?.data?.group === "Member" || state.user?.data?.group === "Prominence Global") && (
        <>
          <li style={{ paddingTop: "10px", borderTop: "1px solid white" }}>
            <StyledNavLink to="/Dashboard">
              <MdInsights />
              <span>Insights</span>
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink to="/LinkSubmit">
              <MdOutlineRocketLaunch />
              <span>Share</span>
            </StyledNavLink>
          </li>
          <li>
            <StyledNavLink to="/Engagement">
              <RxPencil1 />
              <span>Empower</span>
            </StyledNavLink>
          </li>
          <li style={{ paddingBottom: "10px", borderBottom: "1px solid white" }}>
            <StyledNavLink to="/Accountability">
              <FaCheck />
              <span>Review</span>
            </StyledNavLink>
          </li>
        </>
      )}
      {!premiumUser && (
        <li>
          <a style={{ display: "inline-block" }} href="https://bit.ly/45WJd7r" target="_blank" rel="noreferrer">
            <Button sx={styles.UpgradeBtn} variant="contained">
              <img style={styles.ProIcon} src="/images/crown.svg" alt="pro icon" />
              <span>Upgrade to Pro</span>
            </Button>
          </a>
        </li>
      )}
      <li style={{ marginTop: "auto" }}>
        <a
          href="https://help.engage-ai.co/en"
          target="_blank"
          rel="noreferrer"
          className={Styles.AnchorLink}
        >
          <LuHeadphones />
          <span>Help & Strategy Hub</span>
        </a>
      </li>
      <li>
        <a
          href={
            state.user?.data?.group === "Growth Plan" || state.user?.data?.group === "Member"
              ? "https://tally.so/r/mDkRyN"
              : "https://www.facebook.com/groups/engageai"
          }
          target="_blank"
          rel="noreferrer"
          className={Styles.AnchorLink}
        >
          <FaPhoneAlt />
          <span>Contact Us</span>
        </a>
      </li>
      <li>
        <StyledNavLink to="/Feedback">
          <BiSolidMessageDetail />
          <span>Feedback</span>
        </StyledNavLink>
      </li>
      <li>
        <div style={styles.SocialMediaBox}>
          <a href="https://www.facebook.com/groups/engageai" target="_blank" rel="noopener noreferrer">
            <IconButton style={styles.SocialMedia}>
              <FaFacebookF style={styles.SocialMediaIcon} />
            </IconButton>
          </a>
          <a
            href="https://www.linkedin.com/company/engage-ai-official/posts/?feedView=all"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton style={styles.SocialMedia}>
              <FaLinkedinIn style={styles.SocialMediaIcon} />
            </IconButton>
          </a>
        </div>
      </li>
    </ul>
  );
}

export default Nav;
